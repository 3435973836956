@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300&family=Montserrat:wght@100;200;300;400&family=Roboto:wght@300;400;500&display=swap');
@font-face {
  font-family: "Raster";
  src: local("Raster"),
  url("fonts/raster.ttf") format("truetype");
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.App{
  display: grid;
}

h1 {
  font-family: Montserrat, sans-serif;
  font-weight: 100px;
  font-size: 60px;
  color: #fff;
}

#arjunHeader {
  font-size: 100px;
}

h2 {
  font-family: Raster, Montserrat, sans-serif;
  font-weight: 200px;
  font-size: 45px;
  color: #fff;
}

h2 {
  font-family: Raster, Montserrat, sans-serif;
  font-weight: 200px;
  font-size: 45px;
  color: #fff;
}

h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 300px;
  font-size: 27px;
  color: #fff;
  text-align: center;
}
h4 {
  font-family: IBM Plex Mono, monospace;
  font-weight: 100px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

h5 {
  font-family: Montserrat, sans-serif;
  font-weight: 100px;
  font-size: 15px;
  color: #fff;
  text-align: center;
}

p {
  font-size: 20px;
  color: white;
  font-family: Roboto, sans-serif;
  text-align: center;
}

#nameHeader {
  padding-top: 250px;
  padding-bottom: 250px;
  margin-top: 0;
}


#namePanel {
  /*background-color: #e5e5f7;
  opacity: 0.8;
  background-image:  linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777);
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;*/
  background-image: linear-gradient(to bottom, #00ffc4 20%, #0b005d);
  text-align: center;
}

#displayimg {
  max-width: 60%;
  height: auto;
  border: 2px solid #000;
  border-radius: 1000px;
  vertical-align: middle;
}

.centerCont {
  display: flex;
  justify-content: center;
  text-align: center;
}

.whoI {
  padding-top: 40px;
  margin-bottom: 50px;
}

.InfoGrid {
  padding: 0 10%;
}

#whoAmI {
  background-image: linear-gradient(to bottom, #0b005d, #000 40%);
  padding: 10px 20px;
}

.headerLink {
  padding: 0px 30px;
  font-size: 75;
  color: white;
}

.headerLink:hover {
  color: #dcdcdc;
}

#iconsOfHeader {
  padding-top: 50px;
}

.whoIContent {
  padding: 0px 10px 30px 10px;
  padding: 7% 3%;
  line-height: 1.5;
}

.infoPanel {
  padding: 10px 10px;
}

.technologiesList {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
}

.listP {
  margin: 0;
}

.arrowIcon {
  color: #fff;
}

.experienceCard {
  background-color: #171717;
  margin: 20px auto;
}

.cardContent {
  background-color: #171717;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 5px 1rem;
  background-color: #00ffc4;
  color: #171717; 
  position: sticky;
  top: 0;
}

nav {
  margin: 0 auto;
  text-align: center;
}

nav a  {
  margin: 0 2rem;
  color: #171717;
  text-decoration: none;
  font-family: Roboto, sans-serif;
}

nav a:hover {
  color: #909090;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #171717;
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 200%) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #00ffc4;
    transition: 1s;
    
  }

  header .responsive_nav {
    transform: translateY(-100vh);;
  }

}

@media screen and (max-width: 1000px) {
  nav a {
    font-size: 10px;
    margin: 0 12px;
  }
}

header {
  height: 100%;
}

#tsparticles {
  position:fixed;
  width: 100%;
  z-index: -1;
  background-color: #000;
}

#foot {
  margin: 20px auto;
}